































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import NotFound from "./../components/NotFound.vue";
import vsDivider from "./../components/vsDivider.vue";
import VerifiedBadge from "@/components/VerifiedBadge.vue";

@Component({
  components: {
    "not-found": NotFound,
    "vs-divider": vsDivider,
    "verified-badge": VerifiedBadge,
  },
})
export default class PackageView extends Vue {
  metaInfo() {
    return {
      title: `Upload `,
      meta: [
        {
          name: "description",
          content: "Upload vein package",
        },
      ],
    };
  }
  constructor() {
    super();
    (window as any)["UploadView"] = this;
  }
  async created() {}

  async login() {
    await this.$auth.login();
  }
}
