


























































































































import VsDivider from "@/components/vsDivider.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: { VsDivider },
})
export default class PrivacyPolicy extends Vue {
  metaInfo() {
    return  {
      title: `Privacy policy`,
      meta: [
        { name: 'description', content: `Vein gallery privacy policy` }
      ]
    }
  }

  goToBack() {
    this.$router.push({ path: "/" });
  }
}
